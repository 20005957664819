import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromServices from '../../app.generated';
import * as fromStore from '..';
import * as equipmentSummariesActions from '../actions/equipmentsummaries.actions';
import * as equipmentSummariesSelectors from '../selectors/equipmentsummaries.selectors';

@Injectable()
export class EquipmentSummariesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.AppState>,
    private service: fromServices.EquipmentsService,
  ) {}

  getEquipmentSummaries$ = createEffect(() => this.actions$.pipe(
    ofType(equipmentSummariesActions.EquipmentSummariesActionTypes.LoadEquipmentSummaries),
    withLatestFrom(this.store.pipe(select(equipmentSummariesSelectors.getHasLoaded))),
    mergeMap(([ _, hasLoaded ]): Observable<equipmentSummariesActions.EquipmentSummariesActions> => {
      if (hasLoaded) {
        return of(new equipmentSummariesActions.LoadEquipmentSummariesFromStore());
      } else {
        return this.service.get().pipe(
          map((equipments: fromServices.EquipmentSummaryVM[]) => equipments.sort((e1, e2) => e1.name < e2.name ? -1 : 1)),
          map((equipments: fromServices.EquipmentSummaryVM[]) => new equipmentSummariesActions.LoadEquipmentSummariesSuccess(equipments)),
          catchError(error => of(new equipmentSummariesActions.LoadEquipmentSummariesFail(error)))
        );
      }
    })
  ));

  upsertEquipmentSummary$ = createEffect(() => this.actions$.pipe(
    ofType(equipmentSummariesActions.EquipmentSummariesActionTypes.UpsertEquipmentSummary),
    mergeMap((a: equipmentSummariesActions.UpsertEquipmentSummary) => {
      const command = {
        ...a.equipmentSummary,
        equipmentId: a.equipmentSummary.id,
      } as fromServices.UpsertEquipmentCommand;
      return this.service
        .upsertEquipment(command)
        .pipe(
          mergeMap(() => [
            new equipmentSummariesActions.ClearEquipmentSummaries(),
            new equipmentSummariesActions.LoadEquipmentSummaries()
          ]),
          catchError(error => of(new equipmentSummariesActions.UpsertEquipmentSummaryFail(error)))
        );
    })
  ));

  deleteEquipmentSummary$ = createEffect(() => this.actions$.pipe(
    ofType(equipmentSummariesActions.EquipmentSummariesActionTypes.DeleteEquipmentSummary),
    mergeMap((a: equipmentSummariesActions.DeleteEquipmentSummary) => {
      const equipmentId = a.equipmentId;
      return this.service
        .deleteEquipment(equipmentId)
        .pipe(
          mergeMap(() => [
            new equipmentSummariesActions.ClearEquipmentSummaries(),
            new equipmentSummariesActions.LoadEquipmentSummaries()
          ]),
          catchError(error => of(new equipmentSummariesActions.DeleteEquipmentSummaryFail(error)))
        );
    })
  ));
}
